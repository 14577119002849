import { MutationTree } from 'vuex'
import { State, initState } from './_state'
import { DeliveryDataD } from '@/components/drawer/extra-services/delivery-section/delivery-section.d'
export enum MutationsNames {
  SET_STATUS_CHECK = 'set-status-check',
  SET_SELECTED_ADDRESS = 'set-selected-address',
  RESET_ADDRESS_VALIDATION = 'reset-address-validation',
  SET_STATUS_CHECK_CONSUMER = 'set-status-check_consumer',
  SET_STATUS_CHECK_STORE = 'set-status-check_store',
}

export type Mutations = {
  [MutationsNames.SET_STATUS_CHECK](payload: State['statusValidateCheck']): any
  [MutationsNames.SET_SELECTED_ADDRESS](payload: DeliveryDataD): void
  [MutationsNames.SET_STATUS_CHECK_CONSUMER](payload: boolean): any
  [MutationsNames.SET_STATUS_CHECK_STORE](payload: boolean): any
}
export const mutations: MutationTree<State> = {
  [MutationsNames.SET_STATUS_CHECK](
    state: State,
    payload: State['statusValidateCheck']
  ) {
    state.statusValidateCheck = Object.assign(
      {},
      state.statusValidateCheck,
      payload
    )
  },
  [MutationsNames.SET_SELECTED_ADDRESS](state: State, payload: DeliveryDataD) {
    state.selectedAddress = payload
  },
  [MutationsNames.RESET_ADDRESS_VALIDATION](state: State) {
    state.selectedAddress = initState().selectedAddress
    state.statusValidateCheck = initState().statusValidateCheck
  },
  [MutationsNames.SET_STATUS_CHECK_CONSUMER](state: State, payload: boolean) {
    state.statusValidateCheckConsumer = payload
  },
  [MutationsNames.SET_STATUS_CHECK_STORE](state: State, payload: boolean) {
    state.statusValidateCheckStore = payload
  },
}
